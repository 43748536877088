import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { ProviderProvider } from "context/provider";
import { providerReducer, initialState as provider } from "reducers/provider";

import App from "pages";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ProviderProvider initialState={provider} reducer={providerReducer}>
    <App />
  </ProviderProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
